import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-elections',
  templateUrl: './elections.component.html',
  styleUrls: ['./elections.component.css']
})
export class ElectionsComponent implements OnInit,AfterViewInit {

  constructor(private contentful: ContentfulService,public nav: NavbarService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    setTimeout(()=>{
    this.nav.showBack();
    this.nav.show(); 
    this.nav.pageTitle='Election Tips'
    },0)
  }

}
