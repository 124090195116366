import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit,AfterViewInit {
resources:Entry<any>[] = [];
  constructor(private contentful: ContentfulService,public nav: NavbarService) { }

  ngOnInit(): void { 
    this.contentful.getResources()
    .then(resources => this.resources = resources);
  }
  ngAfterViewInit(){
    setTimeout(()=>{
    this.nav.showBack();
    this.nav.show(); 
    this.nav.pageTitle='Resources'
    },0)
  }
}
