<div class="container">
   <span class="gap"></span>
  <div class="news-posts">
   <div *ngFor="let n of news;let i = index">
      <mat-card>
         <mat-card-header></mat-card-header>
         <img mat-card-image [src]="n.fields.image.fields.file.url" />
         <mat-card-content>
            <mat-card-subtitle [innerHTML]="n.fields.date| date: 'fullDate'" ></mat-card-subtitle>
            <mat-card-title [innerHTML]="n.fields.title"></mat-card-title>
            <br>
            <mat-card-subtitle class="read-time"> ~ {{calculateReadTime(i)}} minute read </mat-card-subtitle>
         </mat-card-content>
         <mat-card-actions align="end"> 
            <button mat-fab extended [routerLink]="['/news', n.fields.url]">
               Read
            </button>
         </mat-card-actions>
      </mat-card>
   </div>
  </div>
      <div *ngIf="length == 0">
      
       <span class="gap"></span>
      <h1>No news at this time</h1>
   </div>
 </div>
