import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit,AfterViewInit {

  constructor(private contentful: ContentfulService,public nav: NavbarService,) { }

  ngOnInit(): void {
   
  }
  ngAfterViewInit(){
    setTimeout(()=>{
    this.nav.showBack();
    this.nav.show(); 
    this.nav.pageTitle='Privacy Policy'
    },0)
  }
}
