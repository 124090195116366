<div class="container">
  <span class="gap"></span>
<h1>Our Clubs</h1>
<span class="gap"></span>

<div class="clubs-section">
  <div *ngFor="let club of clubs" class="clubs">
    <a [routerLink]="['/groups',club.fields.url]">
    <img class="clubs-section-logo" [src]="club.fields.image.fields.file.url" alt="" />
    <!-- <div class="club-logo"></div> -->
    <p  [innerHTML]="club.fields.title"></p></a>
  </div>
</div>

</div>