<div class="container">
   <span class="gap"></span>
   <div class="row row-cols-1 row-cols-md-3 g-1">
    <div *ngFor="let p of photos" class="col">
     <div class="card">
        <img [src]="p.fields.image.fields.file.url" class="card-img-top" [alt]="p.fields.title">
    </div>
    </div>
   </div>
</div>
