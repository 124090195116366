import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-clubs',
  templateUrl: './clubs.component.html',
  styleUrls: ['./clubs.component.css']
})
export class ClubsComponent implements OnInit,AfterViewInit {
clubs: Entry<any>[] = [];
  constructor(private contentful: ContentfulService,public nav: NavbarService) { }

  ngOnInit(): void {
    this.contentful.getGroups()
    .then(clubs => this.clubs = clubs);
  }
  ngAfterViewInit(){
    setTimeout(()=>{
    this.nav.showBack();
    this.nav.show(); 
    this.nav.pageTitle='Clubs'
    },0)
  }
}
