<div class="container">
    <div class="row">
     <span class="gap"></span>
     <div class="card-group">
      <div class="card mb-3">
         <div class="row g-0">
           <div class="col-md-4">
             <img class="img-fluid" src="https://images.ctfassets.net/dew243p4qvo2/6PfhUI8gr0vU6Yz8lwQUqe/45cc84bbee9d937ffdd80f0a84725f92/registered-to-vote.jpg" alt="Register to vote sign">
           </div>
           <div class="col-md-8">
             <div class="card-body">
               <h1 class="card-title">REGISTER TO VOTE OR CHANGE YOUR REGISTRATION</h1>
               <p class="card-text">Every Vote Counts! Voting is one of the most important rights and responsibilities that U.S. citizens have.</p>
               <p class="card-text"><small><a href="https://www.sos.la.gov/ElectionsAndVoting/Pages/OnlineVoterRegistration.aspx">Register <mat-icon class="material-icons-round">arrow_forward_ios</mat-icon></a></small></p>
             </div>
           </div>
         </div>
       </div>
     </div>
     <div class="g-4">
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">National Republican Party</h5>
            <p class="card-text">The Republican Party is fighting for a freer and stronger America where everyone has the opportunity to achieve the American Dream.</p>
          </div>
        </div>
      </div>
      <span class="gap"></span>
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Louisiana Republican Party</h5>
            <p class="card-text">The Republican Party of Louisiana is strong, principled and conservative. It is the Party that should lead the state of Louisiana.</p>
          </div>
        </div>
      </div>
    </div>
     <span class="gap"></span>
     <div *ngFor="let r of resources" class="card-group col-lg-4">
         <div class="card">
            <img [src]="r.fields.image.fields.file.url" class="card-img-top img-fluid" [alt]="r.fields.name">
            <div class="card-body">
             <h4 class="card-title"><small [innerHTML]="r.fields.title | mdToHtml"></small></h4>
             <h1 [innerHTML]="r.fields.name | mdToHtml"></h1>
             <mat-toolbar>
                <a *ngIf="r.fields.facebook" href="{{r.fields.facebook}}" target="_blank"><i class="fab fa-facebook-f"></i></a>
                <span class="spacer"></span>
                <a *ngIf="r.fields.instagram" href="{{r.fields.instagram}}" target="_blank"><i class="fab fa-instagram"></i></a>
               <span class="spacer"></span>
                <a *ngIf="r.fields.twitter" href="{{r.fields.twitter}}" target="_blank"><i class="fab fa-twitter"></i></a>
                <span class="spacer"></span>
                <a *ngIf="r.fields.youtube" href="{{r.fields.youtube}}" target="_blank"><i class="fab fa-youtube"></i></a>
                 <span class="spacer"></span>
                <a *ngIf="r.fields.website" href="{{r.fields.website}}" target="_blank" ><small>Visit Website <mat-icon>arrow_forward_ios</mat-icon></small></a>
                </mat-toolbar>
             </div>
        </div>
     </div>
    </div>
 </div>