import {Component} from '@angular/core';
import { BreakpointObserver, Breakpoints,BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { NavbarService } from '../../core/navbar.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent{
 

  constructor(public nav: NavbarService,private location:Location) {}

  backClicked() { 
    this.location.back();
  }

  pages=[
    {title:'Home',route:""},
    {title:'Clubs',route:'groups'},
    {title:'News',route:'news'},
    {title:'Events',route:'events'},
    {title:'Resources',route:'resources'},
    {title:'About Us',route:'about'},
    {title:'Contact Us',route:'contact'},
  ]
}  