// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyDf6Yo-X9E8yPUrRhSGihZDcK4g1EyrqBY",
    authDomain: "st-tammany-republicans.firebaseapp.com",
    databaseURL: "https://st-tammany-republicans.firebaseio.com",
    projectId: "st-tammany-republicans",
    storageBucket: "st-tammany-republicans.appspot.com",
    messagingSenderId: "679557452411",
    appId: "1:679557452411:web:092f15eaf1b08e31b8c8de"
  },
  contentful: {
    space: 'dew243p4qvo2',
    accessToken: 'Ktz9IsNDs98rwxrIfFc1XbfpAjAtVNb2cN-mdEj_0uQ'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
