<span class="gap"></span>
<footer>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-4">
      <span class="gap"></span>
      <img class="logo" src="" alt="" defer>
    </div>
    <div class="col-12">
      <br>
      &copy; {{year}} {{title}}. All Rights Reserved
    </div>
  </div>
</div>
<span class="gap"></span>
<span class="gap"></span>
</footer>
<span class="gap"></span>
