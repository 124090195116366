<div class="container">
   <span class="gap"></span>
  <div class="events-posts">
   <div *ngFor="let e of events;let i = index">
      <mat-card>
         <mat-card-header></mat-card-header>
         <img mat-card-image [src]="e.fields.image.fields.file.url" />
         <mat-card-content>
            <mat-card-subtitle [innerHTML]="e.fields.dateTime| date: 'fullDate'" ></mat-card-subtitle>
            <mat-card-title [innerHTML]="e.fields.title"></mat-card-title>
            <div *ngIf="e.fields.forCandidate">
               <hr class="hr-red"/>
            <p>
               Disclaimer: The posting or sharing of any candidate's information does not constitute an endorsement of any candidate.
            </p>
            </div>
         </mat-card-content>
         <mat-card-actions align="end"> 
            <button mat-fab extended [routerLink]="['/events', e.fields.url]">
               Read
            </button>
         </mat-card-actions>
      </mat-card>
   </div>
  </div>
      <div *ngIf="length == 0">
      
       <span class="gap"></span>
      <h1>No events at this time</h1>
   </div>
 </div>
