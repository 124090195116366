<div class="container">
    <span class="gap"></span>
    <img src="https://images.unsplash.com/photo-1556046905-2508233aea86?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80" alt="USA" class="img-fluid">
    
    
    <br>
    <div class="row">
        <div class="container"> 
            <span class="gap"></span>       
            <form class="container card" [formGroup]="myForm" [hidden]="success" (ngSubmit)="onSubmit()">
        <div class="col-12">
          <h2>We’re here to help and answer any question you might have. We look forward to hearing from you! 🙂</h2>
          <span class="gap"></span>
          <!--For testing-->
           <!--Value: {{myForm.value | json}}-->
          <!--Name-->
           <mat-form-field appearance="fill">
             
             <input matInput placeholder="Name" formControlName="name" autocomplete="name">
             
            </mat-form-field>
        </div>
         <!--Email-->
        <div class="col-12 email">
  
            <mat-form-field appearance="fill">
              
              <input matInput placeholder="Email" formControlName="email" autocomplete="email">
                
            </mat-form-field>
            </div>
            <!--Text Area-->
            <div class="col-12 ">
            <mat-form-field appearance="fill">
              
               <textarea rows="5" class="txtarea" matInput placeholder="How Can We Help?" formControlName="message"></textarea>
             </mat-form-field>
         </div>
         <div align="end">
            
        <button mat-fab extended="" [disabled]="myForm.invalid" color="primary" type="submit">Send Message <span class="material-icons-round">
send
</span></button>
         </div>
        
          </form>
        </div>
    </div>
</div>

