import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-events-details',
  templateUrl: './events-details.component.html',
  styleUrls: ['./events-details.component.css']
})
export class EventsDetailsComponent implements OnInit{
  events: Entry<any>;
  constructor(private route: ActivatedRoute,private contentful: ContentfulService,public nav: NavbarService) { }

  ngOnInit(): void {
    //
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getEvent(params.get('slug'))))
    .subscribe( events=> {
      this.events = events
      this.nav.pageTitle= events.fields.title
    });
  }



  share(title:string, url:string){
    if (navigator.share) {
      navigator.share({
        title: title,
        url: `https://stprepublicanparty.com/events/${url}`,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

}
