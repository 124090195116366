<div class="container">
    <span class="gap"></span>
    <div class="row">
        <div class="card-group">
            <div class="card">
                <mat-toolbar>Make financial contributions. <span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>
                    Campaigns cost money to run. Signs, brochures, tee shirts, newspaper ads and media 
                    time are expensive. Candidates appreciate whatever you can give, even as little as $5.00 or $10.00 helps
                </small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Work in a Candidate<br>campaign headquarters.<span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>Schedule yourself "in" for an hour or for a morning or evening or for an entire day -- whatever time you have to dedicate to work at the Headquarters will make a difference.</small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Walk a neighborhood<span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>
                    Give out campaign information. Walk in pairs, it's fun and great exercise.
                </small>
            </div>
        </div>
        <div class="card-group">
            <div class="card">
                <mat-toolbar>Host a "Meet and Greet" <span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>
                    So your friends and neighbors can personally meet and ask questions of your Candidate.
                </small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Host or attend a Fundraiser<br> for your Candidate.<span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>The opposition always watches to see if there is a good turnout for these events. Ensure that your Candidate has a great turnout.</small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Participate in a phone bank<br> for your Candidate. <span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>
                    Training is provided, there is always a script. You are just helping to get the Candidate's message out.
                </small>
            </div>
        </div>
        <div class="card-group">
            <div class="card">
                <mat-toolbar>Provide clerical assistance.<span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>
                    Help with volunteer activities for your Candidate. Volunteers with organizational skills are a necessity to every campaign.
                </small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Put a bumper sticker on your car.<span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>They come off easily, we promise</small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Put a sign in your yard <span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>
                    Volunteer to put signs out in approved sign locations
                </small>
            </div>
        </div>
        <div class="card-group">
            <div class="card">
                <mat-toolbar>Be a "Sign Waver" on election day.<span class="spacer"></span><mat-icon class="material-icon-round"></mat-icon></mat-toolbar>
                <small>
                    Every Candidate strives to keep the energy level high up until the polls close at 8:00 p.m. Having energized volunteers on the streets waving signs proclaiming their Candidate's name is a great way to remind people to go vote for your Candidate.
                </small>
            </div>
        </div>
    </div>
</div>
