import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

//Firebase
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {AngularFireModule} from '@angular/fire/compat'
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NavbarService } from './core/navbar.service';
import { MdToHtmlPipe } from './core/md-to-html.pipe';
import { ContentfulService } from './core/contentful.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { ElectionsComponent } from './pages/elections/elections.component';
import { TopComponent } from './navigation/top/top.component';
import { BottomComponent } from './navigation/bottom/bottom.component';
import { PrivacyComponent } from './policies/privacy/privacy.component';
import { TermsComponent } from './policies/terms/terms.component';
import { ClubsComponent } from './clubs/clubs/clubs.component';
import { ClubDetailsComponent } from './clubs/club-details/club-details.component';
import { EventsDetailsComponent } from './events/events-details/events-details.component';
import { EventsComponent } from './events/events/events.component';
import { NewsComponent } from './news/news/news.component';
import { NewsDetailsComponent } from './news/news-details/news-details.component';
import { PhotosComponent } from './photos/photos.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { FooterComponent } from './footer/footer.component';
import { ChipListComponent } from './features/chip-list/chip-list.component';
import { SortPipe } from './core/sort.pipe';
import { NewslettersComponent } from './clubs/newsletters/newsletters.component';


const routes: Routes = [
  //{path:'', component:}, 
  {path: '',component: HomeComponent},

 {path: 'news',component: NewsComponent},
{path: 'news/:slug',component: NewsDetailsComponent },

 {path: 'about',component: AboutComponent},
 {path: 'contact',component: ContactComponent },

 {path: 'events',component: EventsComponent },
 {path: 'events/:slug',component: EventsDetailsComponent },

 {path: 'elections',component: ElectionsComponent},
 {path: 'resources',component:  ResourcesComponent},

 {path: 'groups',component: ClubsComponent},
 {path: 'groups/:slug',component:ClubDetailsComponent},
 
 {path: 'photos',component :PhotosComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    MdToHtmlPipe,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    ElectionsComponent,
    TopComponent,
    BottomComponent,
    PrivacyComponent,
    TermsComponent,
    ClubsComponent,
    ClubDetailsComponent,
    EventsDetailsComponent,
    EventsComponent,
    NewsComponent,
    NewsDetailsComponent,
    PhotosComponent,
    ResourcesComponent,
    FooterComponent,
    ChipListComponent,
    SortPipe,
    NewslettersComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MaterialModule,
    RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    RouterModule
  ],
  exports:[RouterModule],
  providers: [NavbarService,ContentfulService,SortPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
