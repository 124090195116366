import { AfterViewInit, Component} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { switchMap } from 'rxjs/operators';
import { ContentfulService } from 'src/app/core/contentful.service';


@Component({
  selector: 'app-club-details',
  templateUrl: './club-details.component.html',
  styleUrls: ['./club-details.component.css'],
  
})
export class ClubDetailsComponent {
club: Entry<any>

  constructor( private route: ActivatedRoute,private contentful: ContentfulService,private title: Title, private meta: Meta) {
   this.route.paramMap
   .pipe(switchMap((params: ParamMap) => this.contentful.getGroup(params.get('slug'))))
   .subscribe( club=> {
    this.club = club
    this.title.setTitle(club.fields.title +' |  St. Tammany Parish Republicans'); 

    this.meta.addTags([
      { name: 'url', content: 'https://sttammanyrepublicans.org/groups/'+club.fields.url },
      { name: 'title', content: club.fields.title +' |  St. Tammany Parish Republicans' },
      { name: 'description', content: club.fields.missionabout },
      { name: 'image', content: club.fields.image.fields.file.url},
      { name: 'locale', content: 'en_US' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:image', content: club.fields.image.fields.file.url },
      { name: 'twitter:title', content: club.fields.title +' |  St. Tammany Parish Republicans' },
      { name: 'twitter:description', content: club.fields.missionabout },
      { name:'robots', content:'index, follow'}
  
    ]);
  });
   }

}

