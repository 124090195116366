<div class="container" *ngIf="news">
    <span class="gap"></span>
    <article>
          <img class="img-fluid" [src]="news.fields.image.fields.file.url" [alt]="news.fields.image.fields.file.title">
    <div class="container">
        <div class="article">
            <mat-toolbar>{{news.fields.date| date: 'fullDate'}}</mat-toolbar>
            <span class="gap"></span>

            <article>
                <h1 [innerHTML]="news.fields.title| titlecase"></h1>
                <span class="gap"></span>
                <time [dateTime]="news.fields.date" pubdate="pubdate" ></time>
                <p [innerHTML]="news.fields.longDescription | mdToHtml"></p>
            </article>
        </div>
    </div>  
    </article>

</div>
 