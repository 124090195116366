<span class="gap"></span>
<div class="container" *ngIf="events">
  <div *ngIf="events.fields.isCalendar">
    <img class="calendar-img" [src]="events.fields.image.fields.file.url" [alt]="events.fields.image.fields.file.title">
  </div>
  <div class="events-page" *ngIf="!events.fields.isCalendar">
  <div class="event-text">
    <mat-card>
    <h1 [innerHTML]="events.fields.title |titlecase"></h1>
    <span class="gap"></span>
      <mat-card-header *ngIf="events.fields.clubEvent">
        <img mat-card-avatar [src]="events.fields.clubEvent.fields.image.fields.file.url"/>
        <mat-card-title>Event by</mat-card-title>
        <mat-card-subtitle [innerHTML]="events.fields.clubEvent.fields.title |titlecase"></mat-card-subtitle>
      <span class="gap"></span>
      </mat-card-header>
      <mat-card-content>
        <article>
        <h2 [innerHTML]="events.fields.venue |titlecase"></h2>
        <br>
        <address>
          <p><mat-icon class="material-icons-round">place</mat-icon> &nbsp; <span [innerHTML]="events.fields.address |titlecase"></span></p>
          <span class="gap"></span>

          <p [innerHTML]="events.fields.longDescription|mdToHtml"></p>
          <span class="gap"></span>

            <h4>{{events.fields.rsvp? 'RSVP': 'Contact'}}</h4>
            <div class="contact">
              <a *ngIf="events.fields.email" mat-fab extended [href]="'mailto:'+events.fields.email+'?subject='+events.fields.title" color="primary"><mat-icon class="material-icons-round">email</mat-icon> Email</a>
              <a *ngIf="events.fields.phone" mat-fab extended [href]="'tel:'+events.fields.phone" color="accent"><mat-icon class="material-icons-round">phone</mat-icon> Call</a>
            </div>
            
            <span class="gap"></span>
            
            <div class="date-time">
              <div class="date" [innerHTML]="(events.fields.dateTime|date)"></div>
            <div class="time" [innerHTML]="(events.fields.dateTime|date: 'shortTime')"></div>
            </div>
            
        </address>
        </article>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="img-fluid" >
   <img [src]="events.fields.image.fields.file.url" [alt]="events.fields.image.fields.file.title"> 
   <button class="share"  (click)="share(events.fields.title,events.fields.url)" mat-fab extended><i class="fa-solid fa-share"></i> &nbsp; Share</button>
  </div>
  
</div>
</div>

