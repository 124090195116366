<div class="content">
  <mat-toolbar class="navbar sticky-top navbar-light bg-light text-secondary">
    <mat-icon class="material-icons-round" *ngIf="this.nav.back && (this.nav.isHandset$ | async)!.matches" aria-label="Previous Page" (click)="backClicked()">arrow_back_ios</mat-icon>
     <span *ngIf="!(this.nav.isHandset$ | async)!.matches">St. Tammany Parish Republicans</span> 
    <span class="spacer"></span>
    <div class="links" *ngIf="!(this.nav.isHandset$ | async)!.matches">
    <a  *ngFor="let page of pages"  [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="router" [routerLink]="['/'+page.route]">{{page.title}}</a>
    </div>
    
  </mat-toolbar>
  <router-outlet></router-outlet>
  <span class="gap"></span>
  </div>
  <app-footer></app-footer>
  <app-bottom *ngIf="(this.nav.isHandset$ | async)!.matches && this.nav.visible"></app-bottom>
 
