import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { switchMap } from 'rxjs/operators';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit,AfterViewInit {
  news: Entry<any>;
  events: Entry<any>[] = [];
  elections: Entry<any>;
  photos: Entry<any>;
  carousel: Entry<any>;
  dates: Entry<any>[] = [];
  clubs: Entry<any>[] = [];
  month: Date;
  constructor(private contentful: ContentfulService,public nav: NavbarService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    
    this.month = new Date();
    this.contentful.getCalender()
    .then(dates => this.dates = dates);
    
    this.month = new Date();
    this.contentful.getGroups()
    .then(clubs => this.clubs = clubs);
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getPage(params.get('slug'))))
    .subscribe(carousel => {
      this.carousel = carousel
    });

    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getElection(params.get('slug'))))
    .subscribe( elections=> {
      this.elections = elections
    });

    this.contentful.getEvents()
    .then(events => {this.events = events
    // this.length = this.events.length;
    }); 

    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getNewsItem(params.get('slug'))))
    .subscribe( news=> {
      this.news = news
    });

    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getPhoto(params.get('slug'))))
    .subscribe( photos=> {
      this.photos = photos
    });
  }
  ngAfterViewInit(){
    setTimeout(()=>{
    this.nav.hideBack();
    this.nav.show(); 
    this.nav.pageTitle='Welcome'
    },0)
  }
}
