import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit,AfterViewInit {
  public events: Entry<any>[] = [];
  constructor(private contentful: ContentfulService,public nav: NavbarService,) { }
length;
  ngOnInit(): void {
    this.contentful.getEvents()
    .then(events => {this.events = events
    this.length = this.events.length;
    }); 
  }
  ngAfterViewInit(){
    setTimeout(()=>{
    this.nav.showBack();
    this.nav.show(); 
    this.nav.pageTitle='Events'
    },0)
  }
}
