<!--Hero Section-->
<div class="">
  <div class="hero-section">
<div>
  <span class="gap"></span>
  <h1>Welcome to St. Tammany Parish Republicans</h1>
  <span class="gap"></span>
  <p class="hero-section-text">We hope that your visit to this website will provide you with the political insight to make informed decisions in voting for candidates and propositions.</p>
  <span class="gap"></span>
  <div>
    <button class="hero-section-primary-cta" mat-flat-button routerLink="/events" color="primary">Upcoming Events</button>
    <button mat-button routerLink="/contact">Contact Us</button>
  <span class="gap"></span>
  </div>
  <div class="hero-data">
    <span class="hero-data-number">87K</span>
    <div>
      <p class="hero-data-text">Registered Republicans in St. Tammany Parish </p>
      <a href="https://voterportal.sos.la.gov/VoterRegistration" mat-button color="primary">Join Us</a>
    </div>
  </div>
</div>
<div class="hero-section-image" [style.background]="'url('+'assets/images/american-flag.svg'+')'">

  <!-- <img class="hero-section-img" src="assets/images/american-flag.jpg" alt="Hero Section  Image Of an American Flag" defer/> -->
</div>
</div>
</div>
<span class="gap"></span>
<!-- Events & News -->

<div class="container">
  <span class="gap"></span>
<h2>Stay Up To Date</h2>
<span class="gap"></span>
 <div class="events-section">
  <div>
    <mat-card *ngFor="let events of events | slice: 0:1" class="action-card"  [style.background]="'url('+events.fields.image.fields.file.url+')'">
      <div class="blur">
          <div class="card-bottom">
            <div>
              <h4 [innerHTML]="events.fields.title"></h4> 
              <p [innerHTML]="events.fields.dateTime| date: 'mediumDate'"></p>
            </div>
            <button mat-mini-fab [routerLink]="['/events',events.fields.url]"><mat-icon class="material-icons-round learn-more">arrow_outward</mat-icon></button>
          </div>
      </div> 
  </mat-card> 
  </div>
  <div>
    <div class="event-item">
      <mat-card *ngFor="let events of events | slice: 1:2" class="second-event" [style.background]="'url('+events.fields.image.fields.file.url+')'">
        <div class="blur">
            <div class="card-bottom">
              <div>
                <h4 [innerHTML]="events.fields.title"></h4> 
                <p [innerHTML]="events.fields.dateTime| date: 'mediumDate'"></p>
              </div>
              <button mat-mini-fab [routerLink]="['/events',events.fields.url]" ><mat-icon class="material-icons-round learn-more">arrow_outward</mat-icon></button>
            </div>
        </div> 
    </mat-card> 
    </div>
    <div class="news-item">
      <mat-card *ngIf="news" class="news-card" [style.background]="'url('+news.fields.image.fields.file.url+')'">
        <div class="blur">
            <div class="card-bottom">
              <div>
                <h4 [innerHTML]="news.fields.title"></h4> 
                <p [innerHTML]="news.fields.date| date: 'mediumDate'"></p>
              </div>
              <button mat-mini-fab [routerLink]="['/news',news.fields.url]" ><mat-icon class="material-icons-round learn-more">arrow_outward</mat-icon></button>
            </div>
        </div> 
    </mat-card> 
    </div>
  </div>
</div>
<span class="gap"></span>

<!-- Clubs -->
<span class="gap"></span>
<h2>Our Clubs</h2>
<span class="gap"></span>

<div class="clubs-section">
  <div *ngFor="let club of clubs" class="clubs">
    <a [routerLink]="['/groups',club.fields.url]">
    <img class="clubs-section-logo" [src]="club.fields.image.fields.file.url" alt="" />
    <!-- <div class="club-logo"></div> -->
    <p  [innerHTML]="club.fields.title"></p></a>
  </div>
</div>

</div>
<span class="gap"></span>
<!-- Photos -->

<div class="photo-section">
  <span class="gap"></span>
  <div class="container">
  <h2 class="photo-section-header">Photos From Our Events</h2>
  <span class="gap"></span>
  <div class="photo-section-image">
    <img src="assets/images/photos-image.png" alt="Photos From Our Event">
  </div>
  <span class="gap"></span>
  <div class="photo-section-button">
    <button mat-fab extended="" class="see-more" routerLink="/photos">
    See more <span class="material-icons-round see-more-icon">
      arrow_forward_ios
      </span>
    </button>
  </div>

  </div>
</div>

