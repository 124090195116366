import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from '../core/contentful.service';
import { NavbarService } from '../core/navbar.service';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit,AfterViewInit {
  public photos: Entry<any>[] = [];
  constructor(private contentful: ContentfulService,public nav: NavbarService,) { }

  ngOnInit(): void {
    this.contentful.getPhotos()
    .then(photos => this.photos = photos);
  }
  ngAfterViewInit(){
    setTimeout(()=>{
    this.nav.showBack();
    this.nav.show(); 
    this.nav.pageTitle='Pics From Events'
    },0)
  }
}
