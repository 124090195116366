import { Component, OnInit } from '@angular/core';
import { AngularFirestore} from '@angular/fire/compat/firestore';

import {UntypedFormBuilder, UntypedFormGroup,Validators, FormControl} from "@angular/forms";
import {FloatLabelType} from '@angular/material/form-field';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  myForm: UntypedFormGroup;
  
  loading = false;
  success = false;
floatLabelControl = new FormControl('auto' as FloatLabelType);
    constructor(private fb: UntypedFormBuilder,private afs: AngularFirestore) { }

    ngOnInit() {
    
      this.myForm =this.fb.group({
        name:['',[
          Validators.required,
          Validators.pattern('[a-zA-Z .-]*'),
          
  
      ]],
        email:['',[
          Validators.required,
          Validators.email
        ]],

        message:['',[
  
        ]]
        
      });
      //this.myForm.valueChanges.subscribe(console.log)
    }
    get name(){
      return this.myForm.get('name');
    }
    get email(){
      return this.myForm.get('email');
    }
    get message(){
      return this.myForm.get('message')
    }
 
    async onSubmit(){
      this.loading = true;
      const formValue ={
        name:this.myForm.value.name,
        email:this.myForm.value.email,
        message:this.myForm.value.message,
        createdAt: new Date()
       
      } 
      try {
          await this.afs.collection('contact').add(formValue);
         this.success = true;
         this.myForm.reset();
         this.myForm.clearValidators();
      }catch(err){
      console.error(err)
      
      }
      this.loading = false;
    }

}
