import { Component, OnInit } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.css']
})
export class ChipListComponent implements OnInit {
  clubs: Entry<any>[] = [];
  constructor(private contentful: ContentfulService,public nav: NavbarService,) { }

  ngOnInit(): void {
    this.contentful.getGroups()
    .then(clubs => this.clubs = clubs);
  }
}
