<div class="newsletter-toolbar">
    <mat-list>
        <mat-list-item>
            <h2 matLine><i class="far fa-newspaper"></i> Newsletters</h2>
            <button mat-icon-button (click)="closeNewsletter($event)"><mat-icon class="material-icons-round" color="accent">close</mat-icon></button>
        </mat-list-item> 
    </mat-list>
<mat-divider></mat-divider>
    
</div>
<div class="newsletters" *ngIf="data.newsletters">
    <mat-nav-list>
      <a mat-list-item *ngFor="let newsletter of data.newsletters" [href]="'https:'+newsletter.fields.file.url" target="_blank">
        <mat-icon matListIcon class="material-icons-round" color="primary">newspaper</mat-icon>
        <h3 matLine [innerText]="newsletter.fields.title"></h3>
        <button mat-icon-button><mat-icon class="material-icons-round" color="primary">navigate_next</mat-icon></button>
      </a>
    </mat-nav-list>
  </div>