import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/core/contentful.service';
import { NavbarService } from 'src/app/core/navbar.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent{
  news: Entry<any>[] = [];
 length: number;
  constructor(private contentful: ContentfulService) {
        this.contentful.getNews()
    .then(news =>{ this.news = news
      this.length = this.news.length
      
    }); 
    
   }

  calculateReadTime(index: number) {
    // Estimate the number of words in the text
    const text = this.news[index].fields.longDescription
    const wordCount = text.split(' ').length;
  
    // Estimate the number of minutes it will take to read the text, assuming an average reading speed of 200 words per minute
    const readTime = Math.round(wordCount / 200);
  
    return readTime;
  }
}
