// ./src/app/contentful.service.ts
import { Injectable } from '@angular/core';
// import Contentful createClient and type for `Entry`
import { createClient, Entry } from 'contentful';
import {marked} from 'marked';
import {environment} from '../../environments/environment';
// configure the service with tokens and content type ids
// SET YOU OWN CONFIG here
const CONFIG = {
  space: environment.contentful.space,
  accessToken: environment.contentful.accessToken,

  contentTypeIds: {
    Events:'Events',
    News:'News',
    Gallery:'Gallery',
    Groups:'Clubs',
    Resources:'Resources',
    pageImages:'pageImages',
    elections:'elections',
    calendar:'calendar'

  }
}

@Injectable()
export class ContentfulService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  constructor() { }
  
  getCalender(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.calendar
    }, query))
    .then(res => res.items);
  }
getEvents(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.Events,
    order:'fields.dateTime'
  }, query))
  .then(res => res.items);
}
getEvent(slug: string): Promise<Entry<any>> {
  return this.getEvents({ 'fields.url': slug })
  .then(items => items[0])
}
//News
getNews(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.News,
    order:'-fields.date'
  }, query))
  .then(res => res.items);
}
getNewsItem(slug: string): Promise<Entry<any>> {
  return this.getNews({ 'fields.url': slug })
  .then(items => items[0])
}
//Groups
getGroups(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.Groups
  }, query))
  .then(res => res.items);
}
getElections(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.elections
  }, query))
  .then(res => res.items);
}
getElection(slug: string): Promise<Entry<any>> {
  return this.getElections({ 'fields.url': slug })
  .then(items => items[0])
}
getGroup(slug: string): Promise<Entry<any>> {
  return this.getGroups({ 'fields.url': slug })
  .then(items => items[0])
}
 
//Gallery
getPhotos(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.Gallery
  }, query))
  .then(res => res.items);
}
getPhoto(slug: string): Promise<Entry<any>> {
  return this.getPhotos({ 'fields.url': slug })
  .then(items => items[0])
}

//Resources
getResources(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.Resources
  }, query))
  .then(res => res.items);
}
getResource(slug: string): Promise<Entry<any>> {
  return this.getResources({ 'fields.url': slug })
  .then(items => items[0])
}

//Images For Pages
getPageImages(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.pageImages
  }, query))
  .then(res => res.items);
}
getPage(slug: string): Promise<Entry<any>> {
  return this.getPageImages({ 'fields.url': slug })
  .then(items => items[0])
}

 // convert markdown string to 
 markdownToHtml(md: string) {

  return marked(md)
}
}