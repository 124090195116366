import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { ContentfulService } from 'src/app/core/contentful.service';

import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {
news: Entry<any>;
  constructor(private route: ActivatedRoute,private contentful: ContentfulService) { }

  ngOnInit(): void {
    //
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getNewsItem(params.get('slug'))))
    .subscribe( news=> {
      this.news = news
    
      
    });
  }

}
