import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../../core/navbar.service';
export interface Pages{
  icon: string;
  page: string;
  text: string;
}
@Component({
  selector: 'app-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.css']
})
export class BottomComponent{
  pages: Pages[] = [
    {icon: 'home',page:'',text:'Home'},
    {icon: 'group',page:'/groups',text:'Clubs'},
    {icon: 'event',page:'/events',text:'Events'},
    {icon: 'article',page:'/news',text:'Articles'},
    {icon: 'info',page:'/resources',text:'Resources'},
  ];
  constructor(public nav: NavbarService) { }


}
