<div class="container">
    <span class="gap"></span>
    <div class="card">
        <h2>In St. Tammany Parish there are presently over 86,000 registered Republicans. We are the plurality party of choice working 
            towards registering voters as Republicans to become the majority party. We hope that your visit to this website will provide 
            you with the political insight to make informed decisions in voting for candidates and propositions. Please take time to visit each group's individual page.
            We encourage you to get active in our party and if you or your friends are not already Republicans, please <a href="https://voterportal.sos.la.gov/VoterRegistration" class="links">join us now</a></h2>
    </div>
    <span class="gap"></span>
    <div class="card col-lg-4">
        <h1>We are the Party of</h1>
    </div>
    <span class="gap"></span>
    <div class="row">
        <div class="card-group">
            <div class="card">
                <mat-toolbar>Freedom <span class="spacer"></span> <img src="https://images.ctfassets.net/dew243p4qvo2/4ZTkEcIMEuVuLuS9SjZgH0/94d8211b9ba0c0cf3d51de44ab601493/breaking.svg" alt="Broken Chain" class="icon"></mat-toolbar>
                <small>Republicans envision “free soil, free speech, free labor”</small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Prosperity <span class="spacer"></span> <img src="https://images.ctfassets.net/dew243p4qvo2/TElMN7LfBPe1JzbdZJ0Tf/5bea38158917d65bfc2a701bc003ae16/money-bag.svg" alt="Money Bag" class="icon"></mat-toolbar>
                <small>Low taxes, sound money, regulatory restraint</small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>Vision <span class="spacer"></span><img src="https://images.ctfassets.net/dew243p4qvo2/17AVC3VOcMreVEZFo7qqJE/4822ba31201d05fe22af3098db85c96d/focus.svg" alt="Svg Of Eye in focus frame" class="icon"></mat-toolbar>
                <small>Governing government of hope, confidence, and growth</small>
            </div>
        </div>
        <span class="gap"></span>
        <div class="card-group">
            <div class="card">
                <mat-toolbar>Strength <span class="spacer"></span> <img src="https://images.ctfassets.net/dew243p4qvo2/2WG5r2sTRr9qp0dR53iM8H/b9f1e027fd63a1b04c12631686004c83/weightlifting.svg" alt="Flexed Biscep with weight" class="icon"></mat-toolbar>
                <small>Led western democracies to victory over the fight against international terrorism</small>
            </div><span class="space"></span>
            <div class="card">
                <mat-toolbar>The Future <span class="spacer"></span><img src="https://images.ctfassets.net/dew243p4qvo2/4lRb0t66gWAQOuNOZpYdZU/716a92ac6caa830e2fb9966d3465dc41/shuttle.svg" alt="Rocket Ship" class="icon"></mat-toolbar>
                <small>Make government effective and efficient, spur economic growth and strive to put power in the hands of the people.</small>

            </div>
        </div>
    </div>
</div>
