import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA,MatBottomSheetRef} from '@angular/material/bottom-sheet';
@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrls: ['./newsletters.component.css']
})
export class NewslettersComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<NewslettersComponent>,@Inject(MAT_BOTTOM_SHEET_DATA) public data:{newsletters:any[]}) { }

  ngOnInit(): void {
  }
  closeNewsletter(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
